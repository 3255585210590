import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa'; // Importa el icono de búsqueda
import { GrClearOption } from 'react-icons/gr';

const Pager = (props) => {
  const { data, canQuery, handleFilterQuery, isAllClientsCheckVisible } = props;

  const { handleNextPage, handlePreviusPage } = props.pager;
  const pagerData = data.pagination;

  const allPages = Array.from({ length: pagerData?.totalPages }, (_, i) => i + 1);

  const numbers = lessPages(allPages, pagerData?.currentPage, pagerData?.totalPages);

  const [searchQuery, setSearchQuery] = useState('');
  const [allClients, setAllClients] = useState(false);

  const handleSearch = () => {
    // console.log('Search query:', searchQuery);
    // Aquí puedes agregar la lógica para realizar la búsqueda

    handleFilterQuery(searchQuery, allClients);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    handleFilterQuery('', allClients);
  };

  const handleCheckAllClients = (checked) => {
    setAllClients(checked);
    handleFilterQuery(searchQuery, checked);
  };

  return (
    <div className="mt-4 flex justify-between">
      {canQuery && (
        <div className="flex space-x-2">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Buscar..."
            className="w-96 rounded border px-2 py-1"
          />
          <button
            onClick={handleSearch}
            className=" rounded bg-gray-600 p-2  text-white hover:bg-gray-800">
            <FaSearch /> {/* Usa el icono en lugar del texto */}
          </button>
          <button
            onClick={handleClearSearch}
            className=" rounded bg-gray-600 p-2  text-white hover:bg-gray-800">
            <GrClearOption /> {/* Usa el icono en lugar del texto */}
          </button>
          {isAllClientsCheckVisible && (
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={allClients}
                onChange={(e) => handleCheckAllClients(e.target.checked)}
              />
              <span>Ver todos los clientes</span>
            </label>
          )}
        </div>
      )}
      {!canQuery && (
        <div>
          <h2 className="text-sm text-gray-600"></h2>
        </div>
      )}
      <div className="flex space-x-4">
        <h2 className="text-sm text-gray-600">Páginas</h2>
        <h2 className="text-sm text-gray-800">
          {numbers.map((number) =>
            number === pagerData?.currentPage ? (
              <span
                key={number}
                className="cursor-not-allowed">
                <strong>{number} </strong>
              </span>
            ) : (
              <span
                key={number}
                className="cursor-pointer"
                onClick={() => handleNextPage(number)}>
                {number}{' '}
              </span>
            )
          )}
        </h2>
        <h2 className="text-sm text-gray-600">Regs: </h2>
        <h2 className="text-sm text-gray-800">
          <strong>{pagerData?.totalRecords}</strong>
        </h2>
        <h3
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => handleNextPage(1)}>
          {`<<`}
        </h3>
        <h3
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => handlePreviusPage()}>
          {`<`}
        </h3>
        <h3
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => handleNextPage()}>
          {' '}
          {`>`}
        </h3>
        <h3
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => handleNextPage(allPages.length)}>
          {' '}
          {`>>`}
        </h3>
      </div>
    </div>
  );
};

const lessPages = (numbers, currentPage, totalPages) => {
  const start = currentPage - 10;
  const end = currentPage + 10;

  const fixStart = start < 0 ? 0 : start;
  const fixEnd = end > totalPages ? totalPages : end;

  return numbers.slice(fixStart, fixEnd);
};

export default Pager;
