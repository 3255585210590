import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import Select from 'react-select';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { useEffect } from 'react';
import { ModalTitle } from '../../../global/Styles/titles';

const selectedTab =
  'relative block border-b-2 border-l border-r border-t-2  bg-gray-100 border-gray-200     font-medium text-gray-800 cursor-pointer rounded-t-md';
const noSelectedTab =
  ' border-l border-b-2 border-r border-t-2 border-gray-200  block  font-medium text-gray-500 hover:text-gray-800 cursor-pointer rounded-t-md';
const checkIsTabSelected = (key, estadoAprobado) => {
  if (key === estadoAprobado) {
    return selectedTab;
  }
  return noSelectedTab;
};
export const ModalAgregarVehiculo = (props) => {
  const { setModalAgregarVehiculo } = props;
  const { createVehiculo, validate, updateVehiculo } = props.agregarVehiculo;

  const { post, get, put } = useApiClient();
  const storeState = store.getState();

  //+++ ELEMENTOS PARA EL SELECT DE MARCAS +++
  const [marcaOptions, setMarcaOptions] = useState([]);
  // const [equipoOptions, setEquipoOptions] = useState([]);
  const [claseOptions, setClaseOptions] = useState([]);
  const [vehiculoTipoOptions, setVehiculoTipoOptions] = useState([]);
  const [propietarioOptions, setPropietarioOptions] = useState([]);

  const getMarcas = async () => {
    try {
      const response = await get(`/marca`);

      const lst = response.data.map((row) => ({
        label: `${row.MARCA_DESCRIPCION}`,
        value: row.MARCA_ID,
      }));

      setMarcaOptions(lst);
    } catch (error) {}
  };

  // const getEquipo = async () => {
  //   try {
  //     const response = await get(`/equipo`);

  //     const lst = response.data.map((row) => ({
  //       label: `${row.EQUIPO_DESCRIPCION}`,
  //       value: row.EQUIPO_ID,
  //     }));

  //     setEquipoOptions(lst);
  //   } catch (error) {}
  // };

  // const getClaseOptions = async () => {
  //   try {
  //     const response = await get(`/clase`);

  //     const lst = response.data.map((row) => ({
  //       label: `${row.CLASE_DESCRIPCION}`,
  //       value: row.CLASE_ID,
  //     }));

  //     setClaseOptions(lst);
  //   } catch (error) {}
  // };
  const getTipoVehiculoOptions = async () => {
    try {
      const response = await get(`/vehiculoTipo`);

      const lst = response.data.map((row) => ({
        label: `${row.VEHICULO_TIPO_DESCRIPCION}`,
        value: row.VEHICULO_TIPO_ID,
      }));

      setVehiculoTipoOptions(lst);
    } catch (error) {}
  };

  const getTerceroPropietario = async () => {
    try {
      const response = await get(`/tercero?ROL_ID=20`);

      const lst = response.data.map((row) => ({
        label: `${row.TERCERO_DOCUMENTO} - ${row.TERCERO_NOMBRE_BUSQUEDA}`,
        value: row.TERCERO_ID,
      }));

      const tmp = [
        {
          label: 'Ninguno',
          value: '',
        },
        ...lst,
      ];

      setPropietarioOptions(tmp);
    } catch (error) {}
  };

  //--- ELEMENTOS PARA EL SELECT DE MARCAS ---

  // +++ MUTADORES +++
  const setMARCA_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['MARCA_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setEQUIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EQUIPO_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setVEHICULO_TIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['VEHICULO_TIPO_ID'];
    const fieldDescripcion = state.fields['VEHICULO_TIPO_DESCRIPCION'];

    field.change(args[0].value);

    // const result = claseOptions.find((i) => i.value === args[0].value);
    // fieldDescripcion.change(result.label);

    state.formState.submitFailed = true;
  };
  const setTERCERO_TENEDOR_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_TENEDOR_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setTERCERO_PROPIETARIO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_PROPIETARIO_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  // --- MUTADORES ---

  const editMode = storeState.vehiculo.selVehiculo === null ? false : true;

  const proxyTask = async (form) => {
    const withAllValues = {
      ...form,
      VEHICULO_TRAILER: selectedTab === 2 ? ['1'] : ['0'],
      VEHICULO_LINEA: selectedTab === 2 ? '' : form.VEHICULO_LINEA,
      VEHICULO_CC: selectedTab === 2 ? '' : form.VEHICULO_CC,
    };

    if (editMode) updateVehiculo(withAllValues);
    else createVehiculo(withAllValues);
  };

  const getCheckValue = (reg) => {
    const lstValues = [];

    if (reg === 1) lstValues.push('1');

    return lstValues;
  };

  const getEditModeSelectedTab = () => {
    if (!editMode) return 1;

    return storeState.vehiculo.selVehiculo?.VEHICULO_TRAILER === 1 ? 2 : 1;
  };

  const lstCheckVehiculoPropio = getCheckValue(storeState.vehiculo.selVehiculo?.VEHICULO_PROPIO);
  const lstCheckTrailerPropio = getCheckValue(storeState.vehiculo.selVehiculo?.VEHICULO_TRAILER);

  const [selectedTab, setSelectedTab] = useState(getEditModeSelectedTab);

  useEffect(() => {
    getMarcas();
    // getEquipo();
    // getClaseOptions();
    getTipoVehiculoOptions();
    getTerceroPropietario();
  }, []);

  const handleTabClick = (tabID) => {
    setSelectedTab(tabID);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        {/* TABS */}
        <ul className=" flex pt-4 text-center">
          <li className="flex-1">
            <div
              className={checkIsTabSelected(1, selectedTab)}
              onClick={() => handleTabClick(1)}>
              VEHICULO
            </div>
          </li>
          <li className="flex-1">
            <div
              className={checkIsTabSelected(2, selectedTab)}
              onClick={() => handleTabClick(2)}>
              TRAILER
            </div>
          </li>
        </ul>
        {/* </div> */}
        {/* <ModalTitle>{editMode ? 'EDITAR' : 'NUEVO'} VEHÍCULO</ModalTitle> */}
        <Form
          onSubmit={proxyTask}
          validate={validate}
          initialValues={
            editMode
              ? {
                  ...storeState.vehiculo.selVehiculo,
                  VEHICULO_PROPIO: lstCheckVehiculoPropio,
                  VEHICULO_TRAILER: lstCheckTrailerPropio,
                }
              : { VEHICULO_PROPIO: ['1'] }
          }
          mutators={{
            setMARCA_ID,
            setEQUIPO_ID,
            setVEHICULO_TIPO_ID,
            setTERCERO_TENEDOR_ID,
            setTERCERO_PROPIETARIO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmAgregarVehiculo" />

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      {/* +++ SELECT DE MARCAS +++ */}
                      <div className="flex-auto justify-center text-left">
                        <p className="text-sm  text-gray-800">Marca</p>
                        {marcaOptions.length > 0 ? (
                          <Select
                            defaultValue={marcaOptions.find((i) => i.value === storeState.vehiculo.selVehiculo?.MARCA_ID)}
                            onChange={form.mutators.setMARCA_ID}
                            options={marcaOptions}
                            name="selectMarca"
                            className="mt-2"
                          />
                        ) : (
                          <div className=" flex items-center justify-center">
                            <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                          </div>
                        )}
                        <Field name="MARCA_ID">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="hidden"
                              />
                              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                            </>
                          )}
                        </Field>
                      </div>
                      {/* --- SELECT DE MARCAS --- */}
                    </div>
                    <div className="w-1/2">
                      <div className="flex-auto justify-center text-left">
                        <Field name="VEHICULO_PLACA">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">Placa</p>
                              <input
                                {...input}
                                type="text"
                                placeholder="nombre"
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Field name="VEHICULO_MODELO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Modelo</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Modelo"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/2">
                      {/* +++ SELECT DE CLASE +++ */}
                      <div className="flex-auto justify-center text-left">
                        <p className="text-sm text-gray-800">Tipo</p>
                        {vehiculoTipoOptions.length > 0 ? (
                          <Select
                            defaultValue={vehiculoTipoOptions.find((i) => i.value === storeState.vehiculo.selVehiculo?.VEHICULO_TIPO_ID)}
                            onChange={form.mutators.setVEHICULO_TIPO_ID}
                            options={vehiculoTipoOptions}
                            name="selectMarca"
                            className="mt-2"
                          />
                        ) : (
                          <div className=" flex items-center justify-center">
                            <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                          </div>
                        )}
                        <Field name="VEHICULO_TIPO_ID">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="hidden"
                              />
                            </>
                          )}
                        </Field>
                        <Field name="VEHICULO_TIPO_DESCRIPCION">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="hidden"
                              />
                            </>
                          )}
                        </Field>
                      </div>
                      {/* --- SELECT DE CLASE --- */}
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      {selectedTab === 1 && (
                        <Field name="VEHICULO_LINEA">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">Línea</p>
                              <input
                                {...input}
                                type="text"
                                placeholder="Línea"
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                            </>
                          )}
                        </Field>
                      )}
                    </div>
                    <div className="w-1/2"></div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Field name="VEHICULO_EJES">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Ejes</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Ejes"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/2">
                      <div className="flex-auto justify-center text-left">
                        {selectedTab === 1 && (
                          <Field name="VEHICULO_CC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Centro de Costo</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder=""
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="mt-2 flex space-x-4">
                      <div className="w-1/2">
                        <label>
                          <Field
                            name="VEHICULO_PROPIO"
                            component="input"
                            type="checkbox"
                            value="1"
                          />{' '}
                          Vehículo Propio
                        </label>
                      </div>
                      <div className="w-1/2">
                        {/* <label>
                          <Field
                            name="VEHICULO_TRAILER"
                            component="input"
                            type="checkbox"
                            value="1"
                          />{' '}
                          Es Trailer
                        </label> */}
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 flex space-x-4">
                    <div className="w-1/2">
                      {/* +++ SELECT DE EQUIPO +++ */}
                      <div className="flex-auto justify-center text-left">
                        <p className="text-sm text-gray-800">Propietario</p>

                        <Field name="TERCERO_PROPIETARIO_ID">
                          {({ input, meta }) => (
                            <>
                              {propietarioOptions.length > 0 ? (
                                <Select
                                  defaultValue={propietarioOptions.find((i) => i.value === storeState.vehiculo.selVehiculo?.TERCERO_PROPIETARIO_ID)}
                                  onChange={form.mutators.setTERCERO_PROPIETARIO_ID}
                                  options={propietarioOptions}
                                  name="selectPropietario"
                                  className="mt-2"
                                />
                              ) : (
                                <div className=" flex items-center justify-center">
                                  <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                                </div>
                              )}
                              <input
                                {...input}
                                type="hidden"
                              />
                              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                            </>
                          )}
                        </Field>
                        <Field name="TERCERO_PROPIETARIO_NOMBRE">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="hidden"
                              />
                            </>
                          )}
                        </Field>
                      </div>
                      {/* --- SELECT DE EQUIPO --- */}
                    </div>
                    <div className="w-1/2">
                      {/* +++ SELECT DE CLASE +++ */}
                      <div className="flex-auto justify-center text-left">
                        <p className="text-sm text-gray-800">Tenedor</p>

                        <Field name="TERCERO_TENEDOR_ID">
                          {({ input, meta }) => (
                            <>
                              {propietarioOptions.length > 0 ? (
                                <Select
                                  defaultValue={propietarioOptions.find((i) => i.value === storeState.vehiculo.selVehiculo?.TERCERO_TENEDOR_ID)}
                                  onChange={form.mutators.setTERCERO_TENEDOR_ID}
                                  options={propietarioOptions}
                                  name="selectTenedor"
                                  className="mt-2"
                                />
                              ) : (
                                <div className=" flex items-center justify-center">
                                  <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                                </div>
                              )}
                              <input
                                {...input}
                                type="hidden"
                              />
                              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                            </>
                          )}
                        </Field>
                        <Field name="TERCERO_TENEDOR_NOMBRE">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="hidden"
                              />
                            </>
                          )}
                        </Field>
                      </div>
                      {/* --- SELECT DE CLASE --- */}
                    </div>
                  </div>
                </div>

                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalAgregarVehiculo(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

//export default Projects;
export default ModalAgregarVehiculo;
