import React, { useEffect, useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
// import { store } from '../../../../store';
import Select from 'react-select';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import TableManifiesto from './TableManifiesto';
import { ModalTitle } from '../../../global/Styles/titles';

const VehiculoLabel = ({ vehiculoData }) => {
  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};
const EmpleadoLabel = ({ data }) => {
  return <span className={`${data.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${data.isEmployeeMsg || '...'} `}</span>;
};

export const ModalConfirmar = (props) => {
  // const storeState = store.getState();
  const { setModalConfirmar } = props;
  // const { getRoles } = props;
  const { aparejadorOptions, proveedorOptions, trailerOptions, conductorOptions } = props.data;
  const { confirmarSolicitud } = props.endPoint;

  const { get } = useApiClient();

  const [vehiculoOptions, setVehiculoOptions] = useState([]);
  const selVehiculoTipoID = props.tipoVehiculo.selTipoVehiculo?.VEHICULO_TIPO_ID;

  const getVehiculoOptions = async (vehiculoTipoId = selVehiculoTipoID) => {
    const result = await get(`/vehiculo?VEHICULO_TIPO_ID=${vehiculoTipoId}`);

    const lst = result.data.map((row) => ({
      label: `${row.VEHICULO_PLACA} (${row.VEHICULO_TIPO_DESCRIPCION})`,
      value: row.VEHICULO_ID,
      propietario: row.TERCERO_PROPIETARIO_NOMBRE,
      tenedor: row.TERCERO_TENEDOR_NOMBRE,
      propio: row.VEHICULO_PROPIO,
    }));

    setVehiculoOptions(lst);
  };

  useEffect(() => {
    getVehiculoOptions();
  }, []);

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['SOLICITUD_PESO'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = '*';
      }
    });
    return errors;
  };

  const setSOLICITUD_VEHICULO_TIPO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['SOLICITUD_VEHICULO_TIPO'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const [vehiculoData, setVehiculoData] = useState({});
  const [trailerData, setTrailerData] = useState({});
  const [conductorData, setConductorData] = useState({});
  const [aparejadorData, setAparejadorData] = useState({});

  const setVEHICULO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['VEHICULO_ID'];
    field.change(args[0].value);

    setVehiculoData({
      isOurAsset: args[0].propio === 1,
      isOurAssetText: args[0].propio === 1 ? 'Propio' : 'Ext.',
      propietario: args[0].propietario,
      tenedor: args[0].tenedor,
      externoText: args[0].tenedor ? '(T)' : '(P)',
      externoName: args[0].tenedor ? args[0].tenedor : args[0].propietario,
    });

    state.formState.submitFailed = true;
  };

  const setTRAILER_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TRAILER_ID'];
    field.change(args[0].value);

    state.formState.submitFailed = true;

    if (args[0].value === '') {
      setTrailerData({});
      return;
    }

    setTrailerData({
      isOurAsset: args[0].propio === 1,
      isOurAssetText: args[0].propio === 1 ? 'Propio' : 'Ext.',
      propietario: args[0].propietario,
      tenedor: args[0].tenedor,
      externoText: args[0].tenedor ? '(T)' : '(P)',
      externoName: args[0].tenedor ? args[0].tenedor : args[0].propietario,
    });
  };

  const setCONDUCTOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONDUCTOR_TERCERO_ID'];
    field.change(args[0].value);
    console.log('args[0]: ', args[0]);
    state.formState.submitFailed = true;

    if (args[0].value === '') {
      setConductorData({});
      return;
    }

    setConductorData({
      isEmployee: args[0].isEmployee === 1,
      isEmployeeMsg: args[0].isEmployee === 1 ? 'Empleado' : 'Externo',
    });
  };
  const setPROVEEDOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['PROVEEDOR_TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setAPAREJADOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['APAREJADOR_TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;

    if (args[0].value === '') {
      setAparejadorData({});
      return;
    }

    setAparejadorData({
      isEmployee: args[0].isEmployee === 1,
      isEmployeeMsg: args[0].isEmployee === 1 ? 'Empleado' : 'Externo',
    });
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>CONFIRMACION DE VEHICULOS</ModalTitle>
        <TableManifiesto />
        <hr />
        <Form
          onSubmit={confirmarSolicitud}
          validate={validate}
          mutators={{
            setVEHICULO_ID,
            setTRAILER_ID,
            setCONDUCTOR_TERCERO_ID,
            setPROVEEDOR_TERCERO_ID,
            setAPAREJADOR_TERCERO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}
          initialValues={{
            SOLICITUD_ID: props.Solicitud.selSolicitud.SOLICITUD_ID,
            SOLICITUD_VEHICULO_TIPO_ID: props.tipoVehiculo.selTipoVehiculo.SOLICITUD_VEHICULO_TIPO_ID,
          }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmConfirmarSolicitud" />

                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/3">
                      <p className={`mb-2 mt-3 text-sm text-gray-800`}>
                        Vehículo <VehiculoLabel vehiculoData={vehiculoData} />
                      </p>
                      {vehiculoOptions.length > 0 ? (
                        <Select
                          options={vehiculoOptions}
                          defaultValue={vehiculoOptions.find((i) => i.value === props.tipoVehiculo?.selTipoVehiculo?.VEHICULO_ID)}
                          name="selectDestino"
                          onChange={form.mutators.setVEHICULO_ID}
                        />
                      ) : (
                        <div className=" flex items-center justify-center ">
                          <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                        </div>
                      )}
                      <Field name="VEHICULO_ID">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <p className="mb-2 mt-3 text-sm text-gray-800">
                        Trailer <VehiculoLabel vehiculoData={trailerData} />
                      </p>
                      {vehiculoOptions.length > 0 ? (
                        <Select
                          options={trailerOptions}
                          defaultValue={trailerOptions.find((i) => i.value === props.tipoVehiculo?.selTipoVehiculo?.VEHICULO_ID)}
                          name="selectDestino"
                          onChange={form.mutators.setTRAILER_ID}
                        />
                      ) : (
                        <div className=" flex items-center justify-center ">
                          <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                        </div>
                      )}
                      <Field name="TRAILER_ID">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-4 w-1/3">
                      <Field name="SOLICITUD_PESO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Peso</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/3">
                      <p className="mb-2 mt-3 text-sm text-gray-800">
                        Conductor <EmpleadoLabel data={conductorData} />{' '}
                      </p>
                      {conductorOptions.length > 0 ? (
                        <Select
                          options={conductorOptions}
                          defaultValue={conductorOptions.find((i) => i.value === props.tipoVehiculo?.selTipoVehiculo?.VEHICULO_ID)}
                          name="selectDestino"
                          onChange={form.mutators.setCONDUCTOR_TERCERO_ID}
                        />
                      ) : (
                        <div className=" flex items-center justify-center ">
                          <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                        </div>
                      )}
                      <Field name="CONDUCTOR_TERCERO_ID">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="w-1/3">
                      <p className="mb-3 mt-2 text-sm text-gray-800">
                        Aparejador <EmpleadoLabel data={aparejadorData} />
                      </p>
                      {aparejadorOptions.length > 0 ? (
                        <Select
                          options={aparejadorOptions}
                          defaultValue={aparejadorOptions.find((i) => i.value === props.tipoVehiculo?.selTipoVehiculo?.VEHICULO_ID)}
                          name="selectDestino"
                          onChange={form.mutators.setAPAREJADOR_TERCERO_ID}
                        />
                      ) : (
                        <div className=" flex items-center justify-center ">
                          <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                        </div>
                      )}
                      <Field name="APAREJADOR_TERCERO_ID">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/3"></div>
                  </div>

                  <div className="flex space-x-4"></div>
                  <div className="flex space-x-4"></div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalConfirmar(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Confirmar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    roles: state.roles,
    tipoVehiculo: state.tipoVehiculo,
    Solicitud: state.Solicitud,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalConfirmar);
