import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import Select from 'react-select';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import TableEjecucion from './TableEjecucion';
import TableManifiesto from './TableManifiesto';
import TableSolicitudLogs from './TableSolicitudLogs';
import TableSolicitudLogsResumen from './TableSolicitudLogsResumen';
import Rating from 'react-rating';
import { ModalTitle } from '../../../global/Styles/titles';
import { TableVehiculos } from './ModalSeguimiento/TableVehiculos';

const FormularioEvaluacion = ({ onSubmit, editMode, closeForm, onRatingChange, solicitud }) => {
  const validate = (values) => {
    const errors = {};
    const requiredFields = [
      'EV_LOGISTICA',
      'EV_INCIDENTES_PERSONALES',
      'EV_TIEMPO_RESPUESTA',
      'EV_INCIDENTES_VEHICULARES',
      'EV_DESEMPENO_HSEQ',
      'EV_INCIDENTES_AMBIENTALES',
      'EV_SOLUCION_DESVIACIONES',
      'EV_INCIDENTES_CARGA',
      'EV_CALIDAD_SERVICIO',
      'EV_AFECTACION_PATRIMONIO',
      'EV_AFECTACION_COMUNIDAD',
      'EV_AFECTACION_IMAGEN',
      'EV_EVALUADOR',
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    return errors;
  };

  const setLogistica = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_LOGISTICA'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setIncidentesPersonales = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_INCIDENTES_PERSONALES'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setTiempoRespuesta = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_TIEMPO_RESPUESTA'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setIncidentesVehiculares = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_INCIDENTES_VEHICULARES'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setDesempenoHSEQ = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_DESEMPENO_HSEQ'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setIncidentesAmbientales = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_INCIDENTES_AMBIENTALES'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setSolucionDesviaciones = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_SOLUCION_DESVIACIONES'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setIncidentesCarga = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_INCIDENTES_CARGA'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setCalidadServicio = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_CALIDAD_SERVICIO'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setAfectacionPatrimonio = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_AFECTACION_PATRIMONIO'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setAfectacionComunidad = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_AFECTACION_COMUNIDAD'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setAfectacionImagen = (args, state, { setIn, changeValue }) => {
    const field = state.fields['EV_AFECTACION_IMAGEN'];
    field.change(args[0]);
    state.formState.submitFailed = true;
  };

  const [total, setTotal] = useState(0);

  const calculateTotal = (values) => {
    const fieldsToSum = ['EV_LOGISTICA', 'EV_TIEMPO_RESPUESTA', 'EV_DESEMPENO_HSEQ', 'EV_SOLUCION_DESVIACIONES', 'EV_CALIDAD_SERVICIO'];
    const total = fieldsToSum.reduce((total, field) => total + (parseInt(values[field], 10) || 0), 0);
    setTotal(total);
    return total;
    // return fieldsToSum.reduce((total, field) => total + (parseInt(values[field], 10) || 0), 0);
  };

  const initialValues = editMode
    ? {
        EV_LOGISTICA: '' + solicitud.EV_LOGISTICA,
        EV_INCIDENTES_PERSONALES: '' + solicitud.EV_INCIDENTES_PERSONALES,
        EV_TIEMPO_RESPUESTA: '' + solicitud.EV_TIEMPO_RESPUESTA,
        EV_INCIDENTES_VEHICULARES: '' + solicitud.EV_INCIDENTES_VEHICULARES,
        EV_DESEMPENO_HSEQ: '' + solicitud.EV_DESEMPENO_HSEQ,
        EV_INCIDENTES_AMBIENTALES: '' + solicitud.EV_INCIDENTES_AMBIENTALES,
        EV_SOLUCION_DESVIACIONES: '' + solicitud.EV_SOLUCION_DESVIACIONES,
        EV_INCIDENTES_CARGA: '' + solicitud.EV_INCIDENTES_CARGA,
        EV_CALIDAD_SERVICIO: '' + solicitud.EV_CALIDAD_SERVICIO,
        EV_AFECTACION_PATRIMONIO: '' + solicitud.EV_AFECTACION_PATRIMONIO,
        EV_AFECTACION_COMUNIDAD: '' + solicitud.EV_AFECTACION_COMUNIDAD,
        EV_AFECTACION_IMAGEN: '' + solicitud.EV_AFECTACION_IMAGEN,
        EV_EVALUADOR: '' + solicitud.EV_EVALUADOR,
      }
    : {
        EV_LOGISTICA: '5',
        EV_INCIDENTES_PERSONALES: '0',
        EV_TIEMPO_RESPUESTA: '5',
        EV_INCIDENTES_VEHICULARES: '0',
        EV_DESEMPENO_HSEQ: '5',
        EV_INCIDENTES_AMBIENTALES: '0',
        EV_SOLUCION_DESVIACIONES: '5',
        EV_INCIDENTES_CARGA: '0',
        EV_CALIDAD_SERVICIO: '5',
        EV_AFECTACION_PATRIMONIO: '0',
        EV_AFECTACION_COMUNIDAD: '0',
        EV_AFECTACION_IMAGEN: '0',
        EV_EVALUADOR: '',
      };

  console.log(initialValues);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true, values: true }}
      mutators={{
        setLogistica,
        setIncidentesPersonales,
        setTiempoRespuesta,
        setIncidentesVehiculares,
        setDesempenoHSEQ,
        setIncidentesAmbientales,
        setSolucionDesviaciones,
        setIncidentesCarga,
        setCalidadServicio,
        setAfectacionPatrimonio,
        setAfectacionComunidad,
        setAfectacionImagen,
      }}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form="frmEvaluacion" />
          <div className="flex-auto justify-center p-5 text-left">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <p className="mt-3 text-gray-800">Logística y Coordinación del Servicio</p>
                  <table className="ml-4">
                    <thead>
                      <tr>
                        <th>5</th>
                        <th>4</th>
                        <th>3</th>
                        <th>2</th>
                        <th>1</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_LOGISTICA"
                              component="input"
                              type="radio"
                              value="5"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_LOGISTICA"
                              component="input"
                              type="radio"
                              value="4"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_LOGISTICA"
                              component="input"
                              type="radio"
                              value="3"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_LOGISTICA"
                              component="input"
                              type="radio"
                              value="2"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_LOGISTICA"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Incidentes personales</p>
                  <table className="ml-4">
                    <thead>
                      <tr>
                        <th>S</th>
                        <th>N</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_PERSONALES"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_PERSONALES"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Tiempo de Respuesta / Plazo de Ejecución</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_TIEMPO_RESPUESTA"
                              component="input"
                              type="radio"
                              value="5"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_TIEMPO_RESPUESTA"
                              component="input"
                              type="radio"
                              value="4"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_TIEMPO_RESPUESTA"
                              component="input"
                              type="radio"
                              value="3"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_TIEMPO_RESPUESTA"
                              component="input"
                              type="radio"
                              value="2"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_TIEMPO_RESPUESTA"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Incidentes vehiculares</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_VEHICULARES"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_VEHICULARES"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <p className="mt-3 text-gray-800">Desempeño en HSEQ</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_DESEMPENO_HSEQ"
                              component="input"
                              type="radio"
                              value="5"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_DESEMPENO_HSEQ"
                              component="input"
                              type="radio"
                              value="4"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_DESEMPENO_HSEQ"
                              component="input"
                              type="radio"
                              value="3"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_DESEMPENO_HSEQ"
                              component="input"
                              type="radio"
                              value="2"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_DESEMPENO_HSEQ"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Incidentes ambientales</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_AMBIENTALES"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_AMBIENTALES"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <p className="mt-3 text-gray-800">Solución a desviaciones</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_SOLUCION_DESVIACIONES"
                              component="input"
                              type="radio"
                              value="5"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_SOLUCION_DESVIACIONES"
                              component="input"
                              type="radio"
                              value="4"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_SOLUCION_DESVIACIONES"
                              component="input"
                              type="radio"
                              value="3"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_SOLUCION_DESVIACIONES"
                              component="input"
                              type="radio"
                              value="2"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_SOLUCION_DESVIACIONES"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Incidentes con daños a la carga</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_CARGA"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_INCIDENTES_CARGA"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <p className="mt-3 text-gray-800">Calidad del Servicio</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_CALIDAD_SERVICIO"
                              component="input"
                              type="radio"
                              value="5"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_CALIDAD_SERVICIO"
                              component="input"
                              type="radio"
                              value="4"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_CALIDAD_SERVICIO"
                              component="input"
                              type="radio"
                              value="3"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_CALIDAD_SERVICIO"
                              component="input"
                              type="radio"
                              value="2"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_CALIDAD_SERVICIO"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Afectación al patrimonio</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_AFECTACION_PATRIMONIO"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_AFECTACION_PATRIMONIO"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2  border-t ">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Total</p>
                  <p className="mt-3 font-extrabold text-gray-800">{calculateTotal(values)}</p>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Afectación a la comunidad</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_AFECTACION_COMUNIDAD"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_AFECTACION_COMUNIDAD"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 w-1/2 text-gray-800">Evaluador</p>
                  <Field name="EV_EVALUADOR">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="text"
                          placeholder=""
                          disabled={editMode}
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between ">
                  <p className="mt-3 text-gray-800">Afectación a la imagen</p>
                  <table className="ml-4">
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <Field
                              name="EV_AFECTACION_IMAGEN"
                              component="input"
                              type="radio"
                              value="1"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                        <td>
                          <label>
                            <Field
                              name="EV_AFECTACION_IMAGEN"
                              component="input"
                              type="radio"
                              value="0"
                              disabled={editMode}
                            />{' '}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="md:block mt-2 space-x-4 text-center">
              <ButtonTable onClick={closeForm}>Cerrar</ButtonTable>
              {solicitud.EVAL_AT === null && (
                <ButtonTable
                  type="submit"
                  disabled={submitting || pristine}>
                  Enviar
                </ButtonTable>
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export const ModalEvaluacion = (props) => {
  const { setIsModalEvaluacionVisible } = props;
  const { postEvaluacion, getSolicitudEvaluacion } = props.endPoint;
  const { solicitudEval } = props.data;
  const editMode = props.Solicitud.selSolicitud.EVAL_AT !== null;
  const [selSolicitudVehiculoTipo, setSelSolicitudVehiculoTipo] = useState('');

  const handleVehiculoSelect = (id) => {
    return;
  };

  const handleGPS = (id) => {
    return;
  };

  const setItemUsuario = (id) => {
    return;
  };

  useEffect(() => {
    getSolicitudEvaluacion();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none focus:outline-none fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5 shadow-lg">
        <ModalTitle>EVALUACIÓN DEL SERVICIO</ModalTitle>
        <div className="w-full flex-auto justify-center p-1 text-left">
          <TableManifiesto />
        </div>
        <div className="mx-4 px-2">
          <TableVehiculos
            actions={{ handleGPS, setItemUsuario, handleVehiculoSelect }}
            data={{
              solicitudVehiculoTipos: props.Solicitud.selSolicitud.solicitud_vehiculo_tipo,
              selSolicitudVehiculoTipo,
            }}
          />
        </div>
        <FormularioEvaluacion
          onRatingChange={(e) => {}}
          onSubmit={postEvaluacion}
          closeForm={() => setIsModalEvaluacionVisible(false)}
          solicitud={solicitudEval}
          editMode={editMode}
        />
        {editMode && <div className="bg-white px-2 pt-2 text-green-500">Evaluacion realizada : {props.Solicitud.selSolicitud.EVAL_AT}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles, tipoVehiculo: state.tipoVehiculo, Solicitud: state.Solicitud };
};

export default connect(mapStateToProps)(ModalEvaluacion);
