import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useApiClient, validateSuccess2xx } from '../../clients/apiAuth';

import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import ViewAnticipos from './ViewAnticipos';
import {
  setSelSolicitud,
  setSelSolicitudLog,
  setSolicitud,
  setSolicitudInit,
  setSelAsignarBono,
  setAsignarSelBonoInit,
  setTipoVehiculoInit,
} from '../../actions';
import { store } from '../../store';

import { CheckParameter } from '../../utils/security';

const Anticipos = (props) => {
  const [nuevoServicio, setNuevoServicio] = useState(false);

  const [loadingTables, setLoadingTables] = useState(false);

  const [isModalEvaluacionVisible, setIsModalEvaluacionVisible] = useState(false);

  const [isModalAnticipoVisible, setIsModalAnticipoVisible] = useState(false);
  const [isModalAnticipoConsultaVisible, setIsModalAnticipoConsultaVisible] = useState(false);

  const [idSolicitud, setIdSolicitud] = useState('');
  // const [estadoAprobado, setEstadoAprobado] = useState(1);

  const [selectedTab, setSelectedTab] = useState(1);

  const [solicitudResult, setSolicitudResult] = useState([]);
  const [vehiculoTipoOptions, setVehiculoTipoOptions] = useState([]);
  // const [vehiculoOptions, setVehiculoOptions] = useState([]);
  const [trailerOptions, setTrailerOptions] = useState([]);
  const [conductorOptions, setConductorOptions] = useState([]);

  const [aparejadorOptions, setAparejadorOptions] = useState([]);

  const [bonoAsignado, setBonoAsignado] = useState([]);

  const [tipoSolicitud, setTipoSolicitud] = useState(1);

  // const selSolicitudVehiculoTipoID = props.tipoVehiculo.selTipoVehiculo?.pivot?.SOLICITUD_VEHICULO_TIPO_ID;
  const selSolicitudVehiculoTipoID = props.tipoVehiculo.selTipoVehiculo?.SOLICITUD_VEHICULO_TIPO_ID;

  const { get, post, put, DELETE } = useApiClient();

  const [currentPage, setCurrentPage] = useState(
    props.Solicitud.pagination === null || props.Solicitud.pagination === undefined ? 1 : props.Solicitud.pagination.currentPage
  );

  const [filterQuery, setFilterQuery] = useState('');
  const [filterAllClients, setFilterAllClients] = useState(false);

  const handleNextPage = (page) => {
    const totalPages = props.Solicitud.pagination?.totalPages || 0;

    if (totalPages === 0) {
      setCurrentPage(1);
      return;
    }

    if (!page && currentPage === totalPages) return;
    setCurrentPage(page ? page : currentPage + 1);
    getSolicitud(page ? page : currentPage + 1);
  };
  const handlePreviusPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
    getSolicitud(currentPage - 1);
  };

  const handleFilterQuery = (query, allClients) => {
    // console.log('allClients: ', allClients);
    setFilterQuery(query);
    setFilterAllClients(allClients);
    getSolicitud(1, query, allClients);
  };

  const getSolicitud = async (pageInd = currentPage, filter = filterQuery, allClients = filterAllClients) => {
    const estado = 4;
    setLoadingTables(true);
    try {
      const filterParam = filter ? `&QUERY=${filter}` : '';
      const filterAllClientsParam = allClients ? `&ALL_CLIENTS=${allClients}` : '';

      const result = await get(
        `/solicitud?page=${pageInd}&TERCERO_ID=${props.auth.data.user.TERCERO_ID}&SOLICITUD_APROBADA=${estado}&CLIENTE_ID=${props.auth.data.selCliente.TERCERO_ID}&ANTICIPO_ESTADO=${selectedTab}${filterParam}${filterAllClientsParam}`
      );
      setLoadingTables(false);

      // if (!validateSuccess2xx(result)) return CustomToast({ result });
      if (!validateSuccess2xx(result)) return;

      store.dispatch(setSolicitud(result));
    } catch (error) {
      // console.log('Error:', error);
      ErrorToast('Error:', error);
    }
  };

  const registrarAnticipo = async (form) => {
    try {
      const result = await post(
        `/solicitudVehiculoTipo/${form.SOLICITUD_VEHICULO_TIPO_ID}/registrarAnticipo`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();
      setNuevoServicio(false);
      getSolicitud();
      OkToast({ result, message: 'Anticipo guardado!' });
      setIsModalAnticipoVisible(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const resendSiesaAnticipo = async (solicitudID) => {
    try {
      const result = await post(
        `/siesa/${solicitudID}/anticipo`,
        {
          json: JSON.stringify({
            TERCERO_ID: props.auth.data.user.TERCERO_ID,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();

      OkToast({ result, message: 'Enviado...' });
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const handleTabClick = (tabID) => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setSelectedTab(tabID);
  };

  useEffect(() => {
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    getSolicitud();
  }, [selectedTab]);

  const solicitud = props.Solicitud;
  const auth = props.auth;

  return (
    <ViewAnticipos
      endPoint={{
        solicitudResult,
        registrarAnticipo,
        resendSiesaAnticipo,
      }}
      modales={{
        isModalAnticipoVisible,
        setIsModalAnticipoVisible,
        isModalAnticipoConsultaVisible,
        setIsModalAnticipoConsultaVisible,
      }}
      data={{
        idSolicitud,
        vehiculoTipoOptions,
        trailerOptions,
        conductorOptions,
        aparejadorOptions,
        solicitud,
        auth,

        tipoSolicitud,
        selectedTab,
        loadingTables,
        tipoVehiculo: props.tipoVehiculo,
      }}
      actions={{
        handleTabClick,
        handleFilterQuery,
        setBonoAsignado,
        setTipoSolicitud,
      }}
      pager={{ handleNextPage, handlePreviusPage }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    tipoVehiculo: state.tipoVehiculo,
    Solicitud: state.Solicitud,
  };
};

export default connect(mapStateToProps)(Anticipos);
